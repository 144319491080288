// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.flex-layout {
  display: flex;
}

.flex-layout.justify-between {
  justify-content: space-between;
}

.flex-layout.justify-center {
  justify-content: center;
}

.flex-layout.align-center {
  align-items: center;
}

.flex-layout.align-end {
  align-items: flex-end;
}
`, "",{"version":3,"sources":["webpack://./src/components/Layouts/Layout.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;;AAEA;EACE,8BAA8B;AAChC;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,qBAAqB;AACvB","sourcesContent":[".flex-layout {\n  display: flex;\n}\n\n.flex-layout.justify-between {\n  justify-content: space-between;\n}\n\n.flex-layout.justify-center {\n  justify-content: center;\n}\n\n.flex-layout.align-center {\n  align-items: center;\n}\n\n.flex-layout.align-end {\n  align-items: flex-end;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
