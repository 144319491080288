import { React } from "react";
import Clock from "react-live-clock";

const Time = (props) => {
  return (
    <div style={{ marginBottom: "1rem" }}>
      <p>
        <strong style={{ fontSize: "1.5rem" }}>
          <Clock format={"h:mm A"} ticking={true} timezone={"US/Mountain"} />
        </strong>
      </p>
    </div>
  );
};

export default Time;
