import { useState, useEffect } from "react";

import logoSvg from "./logo.svg";
import loadingGif from "./assets/loading.gif";
import "./App.css";

import Logo from "./components/Logo/Logo";
import Time from "./components/Time/Time";

import { Flex } from "./components/Layouts/Layout";

import axios from "axios";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBackspace } from "@fortawesome/free-solid-svg-icons";

function App() {
  const [apiToken, setApiToken] = useState("");
  const [showNumPad, setShowNumPad] = useState(true);
  const [searchResults, setSearchResults] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [userNotes, setUserNotes] = useState("");

  const [screen, setScreen] = useState("loading");
  const [googleData, setGoogleData] = useState([]);
  const [currentUser, setCurrentUser] = useState();
  const [currentUserStatus, setCurrentUserStatus] = useState("");

  const [location, setLocation] = useState("");

  const loadScreen = (screen) => {
    setScreen(screen);
  };

  let tempToken = "";

  const getKey = async () => {
    return await axios
      .post(`/staffcheckin_api/v1/token`, {
        username: "apiuserV1",
        password:
          "EJ5#DezjD4n*6SG5sDe3KMn&%5QhPS6DrJ2dvn!hGmQNnHrnXcSmhXJugZKr@",
      })
      .then((res) => {
        setApiToken(res.data.access_token);
        tempToken = res.data.access_token;

        const getValidList = async () => {
          return await axios
            .post(`/staffcheckin_api/v1/validlist`, {
              token: tempToken,
            })
            .then((res) => {
              setGoogleData(res.data);
              setScreen("home");
              return res.data;
            })
            .catch((err) => {
              if (err.response.data.detail) {
                alert(err.response.data.detail);
              } else {
                alert("#1001 - " + err.response.data);
              }
            });
        };
        getValidList();
      })
      .catch((err) => {
        if (err.response.data.detail) {
          alert(err.response.data.detail);
        } else {
          alert("#1000 - " + err.response.data);
        }
      });
  };

  /** SEARCH Code */
  useEffect(() => {
    getKey();

    const getLocation = async () => {
      return await axios
        .get(`/staffcheckin_api/v1/location`)
        .then((res) => {
          setLocation(res.data[0]);
        })
        .catch((err) => {
          if (err.response.data.detail) {
            alert(err.response.data.detail);
          } else {
            alert("#1002 - " + err.response.data);
          }
        });
    };
    getLocation();

    let refreshData = setInterval(() => {
      getKey();
    }, 60 * 60 * 1000);
  }, []);

  const handleSearchInput = (val) => {
    const searchQuery = val;

    setSearchQuery(searchQuery);

    // If the value is empty let's set to an empty array and exit early
    if (val === "") {
      setShowNumPad(true);
      setSearchResults([]);
      return;
    }

    setSearchResults(
      JSON.parse(googleData).filter(
        (item, index, arr) =>
          item.phone.indexOf(searchQuery) !== -1 ||
          item.email.toLowerCase().indexOf(searchQuery.toLowerCase()) !== -1 ||
          item.fullname.toLowerCase().indexOf(searchQuery.toLowerCase()) !== -1
      ) || []
    );

    if (val.length === 4) {
      setShowNumPad(false);
    } else {
      setShowNumPad(true);
    }
  };

  /** SINGLE USER STUFF */

  const searchItemClickHandler = async (e, item) => {
    await axios
      .post(`/staffcheckin_api/v1/getstatus`, {
        email: item.email,
        token: apiToken,
      })
      .then((res) => {
        setCurrentUserStatus(res.data);
        setCurrentUser(item);
        setScreen("single");
      })
      .catch((err) => {
        if (err.response.data.detail) {
          alert(err.response.data.detail);
        } else {
          alert("#1003 - " + err.response.data);
        }
      });
  };

  const checkoutHandler = async (e, email) => {
    if (e.target.className.indexOf("processing") === -1) {
      const circleLoader = document.querySelector(".circle-loader");

      document.querySelector(".goback").style.pointerEvents = "none";

      if (circleLoader !== null) {
        circleLoader.style.display = "inline-block";
      }

      e.target.classList.toggle("processing");

      const data = {
        email: email,
        location: location,
        operator: "Kiosk",
        notes: userNotes,
        token: apiToken,
      };

      await axios
        .post(`/staffcheckin_api/v1/checkout`, data)
        .then((res) => {
          document
            .querySelector(".circle-loader")
            .classList.toggle("load-complete");
          document.querySelector(".checkmark").style.display = "inline-block";
          setTimeout(() => {
            document.querySelector(".circle-loader").style.display = "none";
            document
              .querySelector(".circle-loader")
              .classList.toggle("load-complete");
            document.querySelector(".checkmark").style.display = "none";

            clearState();
          }, 2000);
        })
        .catch((err) => {
          if (err.response.data.detail) {
            alert(err.response.data.detail);
          } else {
            alert("#1004 - " + err.response.data);
          }
        });
    }
  };

  const checkinHandler = async (e, email) => {
    if (e.target.className.indexOf("processing") === -1) {
      const circleLoader = document.querySelector(".circle-loader");

      if (circleLoader !== null) {
        circleLoader.style.display = "inline-block";
      }

      document.querySelector(".goback").style.pointerEvents = "none";

      e.target.classList.toggle("processing");

      const data = {
        email: email,
        location: location,
        operator: "Kiosk",
        notes: userNotes,
        token: apiToken,
      };

      await axios
        .post(`/staffcheckin_api/v1/checkin`, data)
        .then((res) => {
          document
            .querySelector(".circle-loader")
            .classList.toggle("load-complete");
          document.querySelector(".checkmark").style.display = "inline-block";
          setTimeout(() => {
            document.querySelector(".circle-loader").style.display = "none";
            document
              .querySelector(".circle-loader")
              .classList.toggle("load-complete");
            document.querySelector(".checkmark").style.display = "none";

            clearState();
          }, 2000);
        })
        .catch((err) => {
          if (err.response.data.detail) {
            alert(err.response.data.detail);
          } else {
            alert("#1005 - " + err.response.data);
          }
        });
    }
  };

  /** NUMBERPAD STUFF */
  const numpadKeyClickHandler = (number) => {
    let val = searchQuery;

    if (number === "B") {
      setSearchQuery((prevState) => {
        return prevState.slice(0, -1);
      });
      val = searchQuery.slice(0, -1);
    } else if (number === "C") {
      setSearchQuery("");
      val = "";
    } else {
      setSearchQuery((prevState) => {
        return prevState + "" + number;
      });
      val += "" + number;
    }

    handleSearchInput(val);
  };

  /** CHECKIN STUFF */

  const userNotesChangeHandler = (e) => {
    setUserNotes(e.target.value);
  };

  const userNotesKeydownHandler = (e) => {
    if (e.key === "Enter") {
      e.target.blur();
    }
  };

  /** MISC */
  const clearState = () => {
    setSearchQuery("");
    setSearchResults([]);
    setCurrentUser("");
    setScreen("home");
    setCurrentUserStatus("");
    setUserNotes("");
    setShowNumPad(true);
  };

  const fillNotes = (note) => {
    setUserNotes(note);
  };

  const goBackHandler = (screen) => {
    setUserNotes("");
    loadScreen(screen);
  };

  const showVersion = () => {
    alert("Jesus loves you!\nCurrent version: 1.1.1");
  };

  return (
    <div className="kiosk">
      <Flex
        options={{
          justifyContent: "center",
        }}
        className="flex-centered"
      >
        <div className="center">
          <Logo
            src={logoSvg}
            size={{ w: 150, h: 150 }}
            alt="Sagebrush Logo"
            onClick={showVersion}
          />
          <Time />
        </div>
      </Flex>

      {screen === "loading" && (
        <Flex options={{ justifyContent: "justify-center" }}>
          <img src={loadingGif} alt="Loading..." />
        </Flex>
      )}

      {screen === "home" && (
        <div>
          <div>
            <div>
              <div className="section search-wrap">
                <input
                  type="text"
                  className="search"
                  onChange={(e) => handleSearchInput(e.target.value)}
                  value={searchQuery}
                  disabled={true}
                  placeholder="Last four of your phone number"
                />
                <div className="search-buttons">
                  <div
                    className="button"
                    onClick={() => numpadKeyClickHandler("B")}
                  >
                    <FontAwesomeIcon icon={faBackspace} />
                  </div>
                </div>
              </div>

              {showNumPad && (
                <div>
                  <div className="numpad">
                    <div
                      className="numpad-key"
                      onClick={() => numpadKeyClickHandler(1)}
                    >
                      <span>1</span>
                    </div>
                    <div
                      className="numpad-key"
                      onClick={() => numpadKeyClickHandler(2)}
                    >
                      <span>2</span>
                    </div>
                    <div
                      className="numpad-key"
                      onClick={() => numpadKeyClickHandler(3)}
                    >
                      <span>3</span>
                    </div>
                    <div
                      className="numpad-key"
                      onClick={() => numpadKeyClickHandler(4)}
                    >
                      <span>4</span>
                    </div>
                    <div
                      className="numpad-key"
                      onClick={() => numpadKeyClickHandler(5)}
                    >
                      <span>5</span>
                    </div>
                    <div
                      className="numpad-key"
                      onClick={() => numpadKeyClickHandler(6)}
                    >
                      <span>6</span>
                    </div>
                    <div
                      className="numpad-key"
                      onClick={() => numpadKeyClickHandler(7)}
                    >
                      <span>7</span>
                    </div>
                    <div
                      className="numpad-key"
                      onClick={() => numpadKeyClickHandler(8)}
                    >
                      <span>8</span>
                    </div>
                    <div
                      className="numpad-key"
                      onClick={() => numpadKeyClickHandler(9)}
                    >
                      <span>9</span>
                    </div>
                    <div
                      style={{ visibility: "hidden" }}
                      className="numpad-key"
                      onClick={() => numpadKeyClickHandler("C")}
                    >
                      <span>clear</span>
                    </div>
                    <div
                      className="numpad-key"
                      onClick={() => numpadKeyClickHandler(0)}
                    >
                      <span>0</span>
                    </div>
                    <div
                      style={{ visibility: "hidden" }}
                      className="numpad-key"
                      onClick={() => numpadKeyClickHandler("B")}
                    >
                      <span>backspace</span>
                    </div>
                  </div>
                </div>
              )}

              {!showNumPad && (
                <div className="section">
                  <ul className="search-list">
                    {searchResults.length > 0 &&
                      searchResults.map((item, index) => {
                        return (
                          <li
                            key={index}
                            className="search-item"
                            onClick={(e) => searchItemClickHandler(e, item)}
                          >
                            {item.fullname}
                            <br />
                            <span className="search-item-small">
                              {item.email}
                            </span>
                          </li>
                        );
                      })}

                    {searchResults.length <= 0 && (
                      <div className="no-results">Phone number not found.</div>
                    )}
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>
      )}

      {screen === "single" && (
        <div className="single-screen">
          <div className="section-wrap">
            <h1>{currentUser.fullname}</h1>
            <h3>{currentUser.email}</h3>
          </div>
          <div className="section-wrap" style={{ padding: "0 1rem" }}>
            <input
              type="text"
              className="checkin-notes"
              maxLength={50}
              placeholder="Enter a note here (max 50 chars)"
              value={userNotes}
              onChange={userNotesChangeHandler}
              onKeyDown={userNotesKeydownHandler}
            />
          </div>
          <div className="section-wrap quick-notes-wrap">
            <div className="button" onClick={() => fillNotes("Lunch")}>
              Lunch
            </div>
            <div
              className="button"
              onClick={() => fillNotes("Creative Offsite")}
            >
              Creative Offsite
            </div>
            <div
              className="button"
              onClick={() => fillNotes("Ministry Offsite")}
            >
              Ministry Offsite
            </div>
            <div className="button" onClick={() => fillNotes("Work Errands")}>
              Work Errands
            </div>
            <div className="button" onClick={() => fillNotes("Personal")}>
              Personal
            </div>
          </div>
          <div className="section-wrap button-wrap">
            <div
              className="button half goback"
              onClick={() => goBackHandler("home")}
            >
              GO BACK
            </div>

            {!currentUserStatus && (
              <div
                className="button full checkin"
                onClick={(e) => checkinHandler(e, currentUser.email)}
              >
                CHECK-IN
                <div
                  className="circle-loader"
                  onClick={(e) => e.stopPropagation()}
                >
                  <div className="checkmark draw"></div>
                </div>
              </div>
            )}

            {currentUserStatus && (
              <div
                className="button full checkout"
                onClick={(e) => checkoutHandler(e, currentUser.email)}
              >
                CHECKOUT
                <div
                  className="circle-loader"
                  onClick={(e) => e.stopPropagation()}
                >
                  <div className="checkmark draw"></div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default App;
