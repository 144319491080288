const Logo = (props) => {
  return (
    <div>
      <img
        src={props.src}
        width={props.size.w || "auto"}
        height={props.size.h || "auto"}
        alt={props.alt}
        onClick={props.onClick}
        className="logo"
      />
    </div>
  );
};

export default Logo;
