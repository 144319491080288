import "./Layout.css";

const Flex = (props) => {
  console.log("layout props", props);
  return (
    <div
      className={`flex-layout ${props.options.justifyContent || ""} ${
        props.options.alignItems || ""
      } ${props.className}`}
    >
      {props.children}
    </div>
  );
};

const Section = (props) => {
  return (
    <div>
      <p>Section Layout</p>
    </div>
  );
};

export { Flex, Section };
